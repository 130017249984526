import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import { getAttendee, getUsherAttendees } from "redux/Attendee/actions";
import { getTicketTypesConst } from "redux/TicketTypes/actions";
import searchLogo from "../../assets/images/search.svg";
import Pagintation from "Components/Pagintation";
import PhoneInput from "react-phone-input-2";
import AddModelPopup from "./AddModelPopup";

const UsherSearch = ({
  searchBy,
  searchText,
  number_of_pages,
  setSearchBy,
  setSearchText,
}) => {
  const dispatch = useDispatch();
  const [pageNumber, setPageNumber] = useState(0);
  const [AddPopup, SetAddPopup] = useState(false);

  useEffect(() => {
    if (number_of_pages) {
      dispatch(
        getUsherAttendees({
          pagenumber: 0,
          search: searchText,
          searchby: searchBy,
        })
      );
      dispatch(getTicketTypesConst({ event_id: params.id }));
    }
  }, [pageNumber]);
  const params = useParams();
  const inputs = [
    {
      type: "qrcode",
      label: "Qr code",
    },
    {
      type: "name",
      label: "Name",
    },
    {
      type: "email",
      label: "Email",
    },
  ];
  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        alignItems: "center",
        flexDirection: "column",
        margin: "auto",
        maxWidth: "1620px",
      }}
    >
      <form
        onSubmit={(e) => {
          e.preventDefault();
          dispatch(
            getUsherAttendees({
              pagenumber: 0,
              search: searchText,
              searchby: searchBy,
            })
          );
          dispatch(getTicketTypesConst({ event_id: params.id }));
        }}
        className="row"
        style={{
          padding: "10px",
          width: "900px",
          maxWidth: "100%",
        }}
      >
        {inputs.map((item, index) => (
          <div
            className="col-md-6 col-12 "
            style={{
              display: "flex",
              gap: "10px",
              flexDirection: "column",
              padding: "10px",
            }}
          >
            <span
              style={{
                minWidth: "fit-content",
                fontSize: "16px",
                display: "block",
              }}
            >
              {item.label}
            </span>
            <input
              className="form-control form-control-lg form-control-solid"
              type="text"
              placeholder={item.label}
              value={searchBy === item.type ? searchText : ""}
              disabled={
                searchBy !== item.type && searchText.trim() !== ""
                  ? true
                  : false
              }
              onChange={(e) => {
                setSearchBy(item.type);
                setSearchText(e.target.value);
              }}
            />
          </div>
        ))}

        <div
          className="col-md-6 col-12 "
          style={{
            display: "flex",
            gap: "10px",
            flexDirection: "column",
            padding: "10px",
          }}
        >
          <span
            style={{
              minWidth: "fit-content",
              fontSize: "16px",
              display: "block",
            }}
          >
            Phone
          </span>
          <PhoneInput
            value={searchBy === "mobileno" ? searchText : ""}
            onChange={(phone) => {
              setSearchBy("mobileno");
              setSearchText(phone);
            }}
            disabled={
              searchBy !== "mobileno" && searchText.trim() !== "" ? true : false
            }
            specialLabel=""
            inputClass="form-control form-control-lg form-control-solid"
          />
        </div>

        <div className="d-flex p-2 flex-row justify-content-center gap-5">
          <button
            className="page-header-search"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            onClick={() => {}}
            type="submit"
          >
            <img
              style={{
                width: "1.5rem",
                cursor: "pointer",
              }}
              src={searchLogo}
            ></img>
          </button>
          <button
            className="btn btn-light"
            type="button"
            onClick={() => {
              setSearchText("");
              setSearchBy("");
            }}
          >
            Reset
          </button>
          <button
            className="btn btn-light"
            type="button"
            onClick={() => SetAddPopup(true)}
          >
            Add
          </button>
        </div>
      </form>
      <AddModelPopup AddPopup={AddPopup} SetAddPopup={SetAddPopup} />
      <Pagintation
        setPageNumber={setPageNumber}
        pageNumber={pageNumber}
        pageCount={number_of_pages}
      />
    </div>
  );
};

export default UsherSearch;
