import React, { useEffect, useRef, useState } from "react";

import AsideMenu from "../../Components/Asidemenu2";
import PageHeader from "../../Components/PageHeader";
import { useSelector, useDispatch } from "react-redux";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Modal from "react-modal";
import edit from "../../assets/images/edit.svg";
import del from "../../assets/images/delete.svg";
import inc from "../../assets/images/increase.svg";
import dec from "../../assets/images/decrease.svg";
import Loader from "../../Components/Loader";
import "react-datepicker/dist/react-datepicker.css";
import Pagintation from "../../Components/Pagintation";
import {
  CreateSection,
  DeleteSection,
  EditSection,
  getSections,
} from "redux/PageBuilder/actions";
import {
  CreateQuestion,
  DeleteQuestion,
  EditQuestion,
  getQuestion,
  ModifyOptionsApi,
} from "redux/Questions/actions";
import Switch from "@mui/material/Switch";
import { getEventsDetails } from "redux/Events/actions";
import { useParams } from "react-router";

const initalSection = {
  name: "",
  name_ar: " ",
  type: "",
  shortdesc: "",
  shortdesc_ar: "",
  mandatory: 1,
  questionorder: 1,
  event_id: null,
  minchoice: "",
  maxchoice: "",
  options: [
    {
      name: "",
      name_ar: "",
      listorder: 0,
      selected: 0,
    },
  ],
};
const route = process.env.REACT_APP_IMAGE_URI;

function Questions() {
  const dispatch = useDispatch();
  const { questions, loader, total } = useSelector((state) => state.questions);
  const { selectedCompany, selectedEvent } = useSelector(
    (state) => state.events
  );
  const editor = useRef(null);

  const [searchText, setSearchText] = useState("");
  const [pageNumber, setPageNumber] = useState(0);
  const [updateFlag, setUpdateFlag] = useState(-1);
  const [eventModal, setEventModal] = useState(false);
  const [error, setError] = useState(false);
  const [newSection, setNewSection] = useState(initalSection);
  const { event } = useSelector((state) => state.events);
  const params = useParams();

  useEffect(() => {
    if (params.uid && params.id) {
      dispatch(
        getQuestion({
          tickettype_id: params.uid,
          pagenumber: pageNumber,
        })
      );
      dispatch(getEventsDetails(params.id));
    }
  }, [pageNumber, params]);

  const theme = createTheme({
    palette: {
      primary: {
        main: "#00D7E1",
      },
      secondary: {
        main: "#222222",
      },
    },
  });

  const addind = () => {
    if (
      newSection.name == "" ||
      newSection.shortdesc == "" ||
      newSection.shortdesc == " " ||
      newSection.type == " " ||
      newSection.questionorder == " "
    ) {
      setError(true);
      return;
    }
    if (updateFlag != -1) {
      let body = {
        ...newSection,
        maxchoice: +newSection.maxchoice,
        minchoice: +newSection.minchoice,
      };
      body.id = parseInt(updateFlag);
      body.event_id = parseInt(params.id);
      body.tickettype_id = parseInt(params.uid);
      if (body.type == "text" && body.type == "session") {
        body.options = [];
        body.minchoice = null;
        body.maxchoice = null;
      }
      dispatch(EditQuestion({ body, selectedEvent: params.uid }));
      setEventModal(false);
    } else {
      let body = {
        ...newSection,
        maxchoice: +newSection.maxchoice,
        minchoice: +newSection.minchoice,
      };
      body.event_id = parseInt(params.id);
      body.tickettype_id = parseInt(params.uid);

      if (body.type == "text" && body.type == "session") {
        body.options = [];
        body.minchoice = null;
        body.maxchoice = null;
      }
      dispatch(CreateQuestion({ body, selectedEvent: params.uid }));
      setEventModal(false);
    }
    setNewSection(initalSection);
    setError(false);
    setUpdateFlag(-1);
  };
  const addModalStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      padding: 0,
      transform: "translate(-50%, -50%)",
      borderRaduis: "16px",
      overflowY: "auto",
      height: "90vh",
    },
  };
  const handleToggle2 = (e) => {
    if (newSection.mandatory == 1) {
      setNewSection({ ...newSection, mandatory: 1 });
    } else {
      setNewSection({ ...newSection, mandatory: 1 });
    }
  };
  const ModifyOptions = () => {
    if (updateFlag != -1) {
      const options = newSection;
      let body = {
        id: parseInt(updateFlag),
        options: options?.options || [],
      };
      dispatch(ModifyOptionsApi({ body, selectedEvent: params.uid }));
      console.log(body);
    }
  };
  return (
    <>
      <Modal
        isOpen={eventModal}
        onRequestClose={() => {
          setEventModal(false);
          setNewSection(initalSection);
          setError(false);
          setUpdateFlag(-1);
        }}
        style={addModalStyles}
        ariaHideApp={false}
      >
        <div className="modal-input">
          {updateFlag == -1 && (
            <h1 className="d-flex flex-center align-items-center card-title fw-bolder fs-1 py-5 px-5">
              Create New Question
            </h1>
          )}
          {updateFlag != -1 && (
            <>
              <h1 className="d-flex flex-center align-items-center text-dark-blue fw-bolder fs-1 py-5 px-5">
                Edit Question
              </h1>
            </>
          )}
          <div className="flex-center" style={{ padding: "3rem" }}>
            <div className="row">
              <div
                className="col-12 fv-row mb-5 fv-plugins-icon-container toggle-div"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div style={{ color: "black" }} className="d-flex  fs-7 mb-2">
                  Is the question mandatory ?
                </div>
                <div>
                  <ThemeProvider theme={theme}>
                    <Switch
                      checked={newSection.mandatory == 1 ? true : false}
                      onChange={() => {
                        if (newSection.mandatory == 1) {
                          setNewSection({
                            ...newSection,
                            mandatory: 0,
                          });
                        } else {
                          setNewSection({
                            ...newSection,
                            mandatory: 1,
                          });
                        }
                      }}
                      //color={event.published ? "primary" : "secondary"}
                      value="selectUploadBool"
                    />
                    {newSection.mandatory == 0 ? "No" : "Yes"}
                  </ThemeProvider>
                </div>
              </div>
              <div className="row mb-5">
                <div className="col-12">
                  <span
                    className="d-flex align-items-center py-2 "
                    style={{ color: "black" }}
                  >
                    Type
                  </span>
                  <select
                    style={
                      error && !newSection.type
                        ? { border: "1px solid red" }
                        : {}
                    }
                    type="text"
                    className="form-control form-control-lg form-control-solid"
                    name="type"
                    placeholder=""
                    value={newSection.type}
                    onChange={(e) =>
                      setNewSection({
                        ...newSection,
                        [e.target.name]: e.target.value,
                      })
                    }
                  >
                    <option value={null}></option>
                    <option value={"checkbox"}>Check Box</option>
                    <option value={"radiobutton"}>Radio Button</option>
                    <option value={"image"}>Image</option>
                    <option value={"text"}>Text</option>
                    <option value={"session"}>Sessions</option>
                  </select>
                </div>
              </div>
              <div className="row mb-5">
                <div className="col-12">
                  <span
                    className="d-flex align-items-center py-2 "
                    style={{ color: "black" }}
                  >
                    Question name in English
                  </span>
                  <input
                    style={
                      error && !newSection.name
                        ? { border: "1px solid red" }
                        : {}
                    }
                    type="text"
                    className="form-control form-control-lg form-control-solid"
                    name="name"
                    placeholder=""
                    value={newSection?.name}
                    onChange={(e) =>
                      setNewSection({
                        ...newSection,
                        [e.target.name]: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
              {event.supports_arabic == 1 && (
                <div className="row mb-5">
                  <div className="col-12">
                    <span
                      className="d-flex align-items-center py-2 "
                      style={{ color: "black" }}
                    >
                      Question name in Arabic
                    </span>
                    <input
                      style={
                        error && !newSection.name_ar
                          ? {
                              border: "1px solid red",
                            }
                          : {}
                      }
                      type="text"
                      className="form-control form-control-lg form-control-solid"
                      name="name_ar"
                      placeholder=""
                      value={newSection?.name_ar}
                      onChange={(e) =>
                        setNewSection({
                          ...newSection,
                          [e.target.name]: e.target.value,
                        })
                      }
                    />
                  </div>
                </div>
              )}
              <div className="row mb-5">
                <div className="col-12">
                  <span
                    className="d-flex align-items-center py-2 "
                    style={{ color: "black" }}
                  >
                    Question description
                  </span>
                  <input
                    style={
                      error && !newSection.shortdesc
                        ? { border: "1px solid red" }
                        : {}
                    }
                    type="text"
                    className="form-control form-control-lg form-control-solid"
                    name="shortdesc"
                    placeholder=""
                    value={newSection?.shortdesc}
                    onChange={(e) =>
                      setNewSection({
                        ...newSection,
                        [e.target.name]: e.target.value,
                      })
                    }
                  />
                </div>
                <div style={{ marginTop: "0.5rem" }}>
                  <span>* Description Limit is 255 character</span>
                </div>
              </div>
              {event.supports_arabic == 1 && (
                <div className="row mb-5">
                  <div className="col-12">
                    <span
                      className="d-flex align-items-center py-2 "
                      style={{ color: "black" }}
                    >
                      Question description in Arabic
                    </span>
                    <input
                      style={
                        error && !newSection.shortdesc_ar
                          ? {
                              border: "1px solid red",
                            }
                          : {}
                      }
                      type="text"
                      className="form-control form-control-lg form-control-solid"
                      name="shortdesc_ar"
                      placeholder=""
                      value={newSection?.shortdesc_ar}
                      onChange={(e) =>
                        setNewSection({
                          ...newSection,
                          [e.target.name]: e.target.value,
                        })
                      }
                    />
                  </div>
                </div>
              )}
              {newSection?.type &&
                (newSection.type == "radiobutton" ||
                  newSection.type == "checkbox") && (
                  <>
                    <div className="row mb-5">
                      {newSection?.options.map((option, index) => (
                        <>
                          <div className="col-4">
                            <label
                              style={{
                                color: "black",
                              }}
                              className="d-flex align-items-center fs-7 mb-2"
                            >
                              Option {index + 1} in English
                            </label>
                            <div>
                              <input
                                style={
                                  error && !option.name
                                    ? {
                                        border: "1px solid red",
                                      }
                                    : {}
                                }
                                type="text"
                                className="form-control form-control-lg form-control-solid"
                                name="name"
                                placeholder=""
                                value={option.name}
                                onChange={(e) => {
                                  let section = {
                                    ...newSection,
                                  };
                                  let op = [...section.options];
                                  op[index] = {
                                    ...op[index],
                                    name: e.target.value,
                                  };
                                  setNewSection({
                                    ...newSection,
                                    options: op,
                                  });
                                }}
                              />
                            </div>
                          </div>
                          {event.supports_arabic == 1 && (
                            <div className="col-3">
                              <label
                                style={{
                                  color: "black",
                                }}
                                className="d-flex align-items-center fs-7 mb-2"
                              >
                                Option {index + 1} in Arabic
                              </label>
                              <div>
                                <input
                                  style={
                                    error && !option.name_ar
                                      ? {
                                          border: "1px solid red",
                                        }
                                      : {}
                                  }
                                  type="text"
                                  className="form-control form-control-lg form-control-solid"
                                  name="name_ar"
                                  placeholder=""
                                  value={option.name_ar}
                                  onChange={(e) => {
                                    let section = {
                                      ...newSection,
                                    };
                                    let op = [...section.options];
                                    op[index] = {
                                      ...op[index],
                                      name_ar: e.target.value,
                                    };
                                    setNewSection({
                                      ...newSection,
                                      options: op,
                                    });
                                  }}
                                />
                              </div>
                            </div>
                          )}
                          <div className="col-2">
                            <span
                              className="d-flex align-items-center py-2 "
                              style={{
                                color: "black",
                              }}
                            >
                              Option order
                            </span>
                            <div
                              style={{
                                display: "flex",
                              }}
                            >
                              <img
                                src={dec}
                                onClick={() => {
                                  if (option.listorder != 0) {
                                    let op = [...newSection.options];
                                    op[index].listorder = parseInt(
                                      op[index].listorder - 1
                                    );
                                    setNewSection({
                                      ...newSection,
                                      options: op,
                                    });
                                  }
                                }}
                                style={{
                                  cursor: "pointer",
                                  width: "1.5rem",
                                }}
                              ></img>
                              <span
                                style={{
                                  margin: "0 7px",
                                  fontWeight: "bold",
                                  fontSize: "1.5rem",
                                }}
                              >
                                {option.listorder}
                              </span>
                              <img
                                src={inc}
                                style={{
                                  cursor: "pointer",
                                  width: "1.5rem",
                                }}
                                onClick={() => {
                                  let op = [...newSection.options];
                                  op[index].listorder = parseInt(
                                    op[index].listorder + 1
                                  );
                                  setNewSection({
                                    ...newSection,
                                    options: op,
                                  });
                                }}
                              ></img>
                            </div>
                          </div>
                          <div
                            className="col-2"
                            style={{
                              marginTop: "0.5rem",
                            }}
                          >
                            <label
                              style={{
                                color: "black",
                              }}
                              className="d-flex align-items-center fs-7 mb-2"
                            >
                              Selected
                            </label>
                            <div>
                              <Switch
                                checked={option.selected == 1 ? true : false}
                                onChange={() => {
                                  const updatedOptions = newSection.options.map(
                                    (item, idx) => {
                                      if (idx === index) {
                                        return {
                                          ...item,
                                          selected: item.selected === 1 ? 0 : 1,
                                        };
                                      }
                                      return item;
                                    }
                                  );
                                  setNewSection({
                                    ...newSection,
                                    options: updatedOptions,
                                  });
                                }}
                                color={
                                  option.selected ? "primary" : "secondary"
                                }
                                value="selectUploadBool"
                              />
                            </div>
                          </div>
                          <div
                            className="col-1"
                            style={{
                              position: "relative ",
                              top: "1rem",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              let op = [...newSection.options];
                              op.splice(index, 1);
                              setNewSection({
                                ...newSection,
                                options: op,
                              });
                            }}
                          >
                            <img src={del}></img>
                          </div>
                        </>
                      ))}
                    </div>
                    <div className="row">
                      <div
                        className="col-6 fv-row mb-5 fv-plugins-icon-container"
                        onClick={() => {
                          let op = [...newSection.options];
                          op.push({
                            name: "",
                            name_ar: "",
                            listorder: 1,
                            selected: 0,
                          });
                          setNewSection({
                            ...newSection,
                            options: op,
                          });
                        }}
                      >
                        <img
                          src={inc}
                          style={{
                            cursor: "pointer",
                            width: "1rem",
                            marginInlineEnd: "0.5rem",
                          }}
                        ></img>
                        Add Option
                      </div>
                      <div className="d-flex flex-wrap justify-content-center pb-lg-0 mt-2 mb-10">
                        <button
                          type="button"
                          id="kt_password_reset_submit"
                          className="save-btn fw-bolder"
                          onClick={ModifyOptions}
                        >
                          <span>Save options</span>
                        </button>
                      </div>
                    </div>
                    {newSection.type == "checkbox" && (
                      <div className="row mb-5">
                        <div className="col-6">
                          <span
                            className="d-flex align-items-center py-2 "
                            style={{
                              color: "black",
                            }}
                          >
                            Minimum Choices
                          </span>
                          <input
                            style={
                              error && !newSection.minchoice
                                ? {
                                    border: "1px solid red",
                                  }
                                : {}
                            }
                            type="text"
                            className="form-control form-control-lg form-control-solid"
                            name="minchoice"
                            placeholder=""
                            value={newSection?.minchoice}
                            onChange={(e) =>
                              setNewSection({
                                ...newSection,
                                [e.target.name]: e.target.value,
                              })
                            }
                          />
                        </div>
                        <div className="col-6">
                          <span
                            className="d-flex align-items-center py-2 "
                            style={{
                              color: "black",
                            }}
                          >
                            Maximum Choices
                          </span>
                          <input
                            style={
                              error && !newSection.maxchoice
                                ? {
                                    border: "1px solid red",
                                  }
                                : {}
                            }
                            type="text"
                            className="form-control form-control-lg form-control-solid"
                            name="maxchoice"
                            placeholder=""
                            value={newSection?.maxchoice}
                            onChange={(e) =>
                              setNewSection({
                                ...newSection,
                                [e.target.name]: e.target.value,
                              })
                            }
                          />
                        </div>
                      </div>
                    )}
                  </>
                )}

              <div className="row mb-5">
                <div className="col-6">
                  <span
                    className="d-flex align-items-center py-2 "
                    style={{ color: "black" }}
                  >
                    Question order
                  </span>
                  <div style={{ display: "flex" }}>
                    <img
                      src={dec}
                      onClick={() => {
                        if (newSection.questionorder != 1) {
                          setNewSection({
                            ...newSection,
                            questionorder: newSection.questionorder - 1,
                          });
                        }
                      }}
                      style={{
                        cursor: "pointer",
                        width: "1.5rem",
                      }}
                    ></img>
                    <span
                      style={{
                        margin: "0 7px",
                        fontWeight: "bold",
                        fontSize: "1.5rem",
                      }}
                    >
                      {newSection.questionorder}
                    </span>
                    <img
                      src={inc}
                      style={{
                        cursor: "pointer",
                        width: "1.5rem",
                      }}
                      onClick={() => {
                        setNewSection({
                          ...newSection,
                          questionorder: newSection.questionorder + 1,
                        });
                      }}
                    ></img>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex flex-wrap justify-content-center pb-lg-0 my-10">
              <button
                type="button"
                id="kt_password_reset_submit"
                className="save-btn fw-bolder"
                onClick={addind}
              >
                <span>Save Changes</span>
              </button>
            </div>
          </div>
        </div>
      </Modal>

      <div className="aside-div">
        <AsideMenu selected={"Questions"}></AsideMenu>
        <div className="d-block main-page" style={{ width: "100%" }}>
          <PageHeader
            title={"Questions"}
            EventName={params.id}
            showUrl={true}
          ></PageHeader>
          <div className="event-page">
            {!loader ? (
              <>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {total} results found
                  </div>
                  <Pagintation setPageNumber={setPageNumber}></Pagintation>
                </div>
                <div className="row" style={{ margin: "0" }}>
                  <table className="table table-striped gy-2 gs-7">
                    <thead>
                      <tr
                        className="fw-bolder fs-6  border-bottom border-gray-200"
                        style={{
                          backgroundColor: "#2B6EC8",
                          borderRadius: "16px",
                        }}
                      >
                        <th
                          className="tfirst"
                          style={{
                            width: "5%",
                            color: "white",
                          }}
                        >
                          #
                        </th>
                        <th
                          style={{
                            width: "5%",
                            color: "white",
                          }}
                        >
                          name
                        </th>
                        <th
                          style={{
                            width: "5%",
                            color: "white",
                          }}
                        >
                          Type
                        </th>
                        <th
                          style={{
                            width: "5%",
                            color: "white",
                          }}
                        >
                          Question order
                        </th>
                        <th
                          className="tlast"
                          style={{
                            width: "5%",
                            color: "white",
                          }}
                        >
                          Actions
                        </th>
                      </tr>

                      {questions?.map((code, index) => (
                        <React.Fragment key={index}>
                          <tr className="fw-bolder fs-6 text-gray-400 border-bottom border-gray-200">
                            <th
                              style={{
                                color: "black",
                                width: "25%",
                              }}
                            >
                              {code.id}
                            </th>
                            <th
                              style={{
                                color: "black",
                                width: "25%",
                              }}
                            >
                              {code.name}
                            </th>
                            <th
                              style={{
                                color: "black",
                                width: "25%",
                              }}
                            >
                              {code.type}
                            </th>
                            <th
                              style={{
                                color: "black",
                                width: "25%",
                              }}
                            >
                              {code.questionorder}
                            </th>

                            <th
                              style={{
                                width: "5%",
                              }}
                            >
                              <span
                                onClick={() => {
                                  setUpdateFlag(code.id);

                                  setNewSection({
                                    name: code.name,
                                    name_ar: code.name_ar,
                                    event_id: code.event_id,
                                    mandatory: code.mandatory,
                                    shortdesc_ar: code.shortdesc_ar,
                                    shortdesc: code.shortdesc,
                                    minchoice: code.minchoice,
                                    maxchoice: code.maxchoice,
                                    options: code.options,
                                    type: code.type,
                                    questionorder: code.questionorder,
                                  });
                                  setEventModal(true);
                                }}
                              >
                                <img
                                  src={edit}
                                  style={{
                                    cursor: "pointer",
                                    marginRight: "1rem",
                                  }}
                                ></img>
                              </span>
                              <span
                                onClick={() => {
                                  dispatch(
                                    DeleteQuestion({
                                      updateFlag: code.id,
                                      selectedEvent: params.uid,
                                    })
                                  );
                                }}
                              >
                                <img
                                  src={del}
                                  style={{
                                    cursor: "pointer",
                                  }}
                                ></img>
                              </span>
                            </th>
                          </tr>
                        </React.Fragment>
                      ))}
                    </thead>
                  </table>
                  <div className="d-flex flex-wrap justify-content-center pb-lg-0 my-10">
                    <button
                      type="button"
                      id="kt_password_reset_submit"
                      className="save-btn fw-bolder"
                      onClick={() => {
                        setEventModal(true);
                      }}
                    >
                      <span>Add Question</span>
                    </button>
                  </div>
                </div>
              </>
            ) : (
              <Loader></Loader>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default Questions;
