import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { ToastContainer, toast, cssTransition } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const route = process.env.REACT_APP_API;
export const getAdmins = createAsyncThunk("get/Admins", async (params) => {
  try {
    const response = await axios({
      method: "get",
      url: "userroles/admins/list",
      params: params,
    });
    return response.data;
  } catch (err) {
    //return rejectWithValue(err.response.data);
  }
});
export const getBoothsAdmins = createAsyncThunk(
  "get/BoothsAdmins",
  async ({ id }) => {
    try {
      const response = await axios({
        method: "get",
        url: "userroles/exhibitorSponsorAdmins/list?event_id=" + id + "",
      });
      return response.data;
    } catch (err) {
      //return rejectWithValue(err.response.data);
    }
  }
);
export const getBoothsSales = createAsyncThunk(
  "get/BoothsSales",
  async ({ id }) => {
    try {
      const response = await axios({
        method: "get",
        url: "userroles/exhibitorSponsorSales/list?booth_id=" + id + "",
      });
      return response.data;
    } catch (err) {
      //return rejectWithValue(err.response.data);
    }
  }
);
export const deleteBoothsAdmins = createAsyncThunk(
  "delete/BoothsAdmins",
  async ({ id, selectedEvent }, { dispatch }) => {
    try {
      const response = await axios({
        method: "delete",
        url: "userroles/exhibitorSponsorAdmins/delete/" + id + "",
      });
      if (response.status == 200 || response.status == 204) {
        toast.success("Admin Deleted successfully");
        dispatch(getBoothsAdmins({ id: selectedEvent }));
      }
      return response.data;
    } catch (err) {
      //return rejectWithValue(err.response.data);
    }
  }
);
export const deleteBoothsSales = createAsyncThunk(
  "delete/BoothsSales",
  async ({ id, selectedEvent }, { dispatch }) => {
    try {
      const response = await axios({
        method: "delete",
        url: "userroles/exhibitorSponsorAdmins/delete/" + id + "",
      });
      if (response.status == 200 || response.status == 204) {
        toast.success("Sales Deleted successfully");
        dispatch(getBoothsSales({ id: selectedEvent }));
      }
      return response.data;
    } catch (err) {
      //return rejectWithValue(err.response.data);
    }
  }
);

export const CreateAdmin = createAsyncThunk(
  "create/admin",
  async ({ body }, { dispatch }) => {
    try {
      const response = await axios({
        method: "post",
        url: "userroles/admins/create",

        data: body,
      });
      if (response.status == 200 || response.status == 204) {
        toast.success("Admin Created successfully");
        dispatch(getAdmins());
      }
      return response.data;
    } catch (err) {
      //return rejectWithValue(err.response.data);
    }
  }
);
export const CreateBoothAdmin = createAsyncThunk(
  "create/boothsadmin",
  async ({ body, selectedEvent }, { dispatch }) => {
    try {
      const response = await axios({
        method: "post",
        url: "userroles/exhibitorSponsorAdmins/create",

        data: body,
      });
      if (response.data.status == 0) {
        toast.success("Admin Created successfully");
        dispatch(getBoothsAdmins({ id: selectedEvent }));
      } else {
        toast.error(`${Object.values(response.data.record)}`);
      }
      return response.data;
    } catch (err) {
      //return rejectWithValue(err.response.data);
    }
  }
);
export const CreateBoothSales = createAsyncThunk(
  "create/boothssales",
  async ({ body, selectedEvent }, { dispatch }) => {
    try {
      const response = await axios({
        method: "post",
        url: "userroles/exhibitorSponsorSales/create",

        data: body,
      });
      if (response.data.status == 0) {
        toast.success("Sales Created successfully");
        dispatch(getBoothsSales({ id: selectedEvent }));
      } else {
        toast.error(`${Object.values(response.data.record)}`);
      }
      return response.data;
    } catch (err) {
      //return rejectWithValue(err.response.data);
    }
  }
);
export const EditAdmin = createAsyncThunk(
  "edit/admin",
  async ({ body, selectedEvent }, { dispatch }) => {
    try {
      const response = await axios({
        method: "post",
        url: "userroles/admins/update",

        data: body,
      });
      if (response.status == 200 || response.status == 204) {
        toast.success("Admin Edited successfully");
        dispatch(getAdmins());
      }
      return response.data;
    } catch (err) {
      //return rejectWithValue(err.response.data);
    }
  }
);
export const EditBoothAdmin = createAsyncThunk(
  "edit/boothadmin",
  async ({ body, id, selectedEvent }, { dispatch }) => {
    try {
      const response = await axios({
        method: "post",
        url: "userroles/exhibitorSponsorAdmins/update/" + id + "",

        data: body,
      });
      if (response.data.status == 0) {
        toast.success("Admin Edited successfully");
        dispatch(getBoothsAdmins({ id: selectedEvent }));
      } else {
        toast.error(`${Object.values(response.data.record)}`);
      }
      return response.data;
    } catch (err) {
      //return rejectWithValue(err.response.data);
    }
  }
);
export const EditBoothSales = createAsyncThunk(
  "edit/boothsales",
  async ({ body, id, selectedEvent }, { dispatch }) => {
    try {
      const response = await axios({
        method: "post",
        url: "userroles/exhibitorSponsorAdmins/update/" + id + "",

        data: body,
      });
      if (response.data.status == 0) {
        toast.success("Sales Edited successfully");
        dispatch(getBoothsSales({ id: selectedEvent }));
      } else {
        toast.error(`${Object.values(response.data.record)}`);
      }
      return response.data;
    } catch (err) {
      //return rejectWithValue(err.response.data);
    }
  }
);
export const getSuperAdmins = createAsyncThunk("get/superAdmins", async () => {
  try {
    const response = await axios({
      method: "get",
      url: "userroles/superadmin/list",
    });
    console.log(response, "companies");
    return response.data;
  } catch (err) {
    //return rejectWithValue(err.response.data);
  }
});
export const CreateSuperAdmin = createAsyncThunk(
  "create/superadmin",
  async ({ body }, { dispatch }) => {
    try {
      const response = await axios({
        method: "post",
        url: "userroles/superadmins/create",

        data: body,
      });
      if (response.status == 200 || response.status == 204) {
        toast.success("Super Admin Created successfully");
        dispatch(getSuperAdmins());
      }
      return response.data;
    } catch (err) {
      //return rejectWithValue(err.response.data);
    }
  }
);
export const EditSuperAdmin = createAsyncThunk(
  "edit/superadmin",
  async ({ body, selectedEvent }, { dispatch }) => {
    try {
      const response = await axios({
        method: "post",
        url: "userroles/superadmins/update",

        data: body,
      });

      if (response.status == 200 || response.status == 204) {
        toast.success("Super Admin Edited successfully");
        dispatch(getSuperAdmins());
      }
      return response.data;
    } catch (err) {
      //return rejectWithValue(err.response.data);
    }
  }
);
export const EditTimeline = createAsyncThunk(
  "edit/Tiimeline",
  async ({ id, body, selectedEvent }, { dispatch }) => {
    try {
      const response = await axios({
        method: "put",
        url: `/event/booths/exhibitor-sales-timelines/${id}`,

        data: body,
      });

      if (response.status == 200 || response.status == 204) {
        toast.success("Slots Edited successfully");
        dispatch(getBoothsSales({ id: selectedEvent }));
      }
      return response.data;
    } catch (err) {
      //return rejectWithValue(err.response.data);
    }
  }
);
export const DeleteTimeline = createAsyncThunk(
  "delete/Tiimeline",
  async ({ id, selectedEvent }, { dispatch }) => {
    try {
      const response = await axios({
        method: "delete",
        url: `/event/booths/exhibitor-sales-timelines/${id}`,
      });

      if (response.status == 200 || response.status == 204) {
        toast.success("Slot Deleted successfully");
        dispatch(getBoothsSales({ id: selectedEvent }));
      }
      return response.data;
    } catch (err) {
      //return rejectWithValue(err.response.data);
    }
  }
);

export const getModerators = createAsyncThunk(
  "get/moderators",
  async ({ id, pagenumber }) => {
    try {
      const response = await axios({
        method: "get",
        url: `userroles/ticket-admins/list?event_id=${id}`,

        params: pagenumber,
      });
      return response.data;
    } catch (err) {
      //return rejectWithValue(err.response.data);
    }
  }
);
export const CreateModerator = createAsyncThunk(
  "create/moderator",
  async ({ body }, { dispatch }) => {
    const id = body.get("event_id");
    try {
      const response = await axios({
        method: "post",
        url: "userroles/ticket-admins/create",

        data: body,
      });

      if (response.data.status == 0) {
        toast.success("moderator Created successfully");
        dispatch(getModerators({ id: id, pagenumber: 0 }));
      } else {
        toast.error(`${Object.values(response.data.record)}`);
      }
      return response.data;
    } catch (err) {
      //return rejectWithValue(err.response.data);
    }
  }
);
export const EditModerator = createAsyncThunk(
  "edit/moderator",
  async ({ body }, { dispatch }) => {
    const id = body.get("event_id");

    try {
      const response = await axios({
        method: "post",
        url: "userroles/ticket-admins/update",

        data: body,
      });

      if (response.data.status == 0) {
        toast.success("moderator Edited successfully");
        dispatch(getModerators({ id: id, pagenumber: 0 }));
      } else {
        toast.error(`${Object.values(response.data.record)}`);
      }
      return response.data;
    } catch (err) {
      //return rejectWithValue(err.response.data);
    }
  }
);
export const getUshers = createAsyncThunk(
  "get/ushers",
  async ({ id, pagenumber }) => {
    try {
      const response = await axios({
        method: "get",
        url: "event/ushers/list/" + id + "",

        params: pagenumber,
      });
      console.log(response, "companies");
      return response.data;
    } catch (err) {
      //return rejectWithValue(err.response.data);
    }
  }
);
export const getAllUshers = createAsyncThunk(
  "get/all/ushers",
  async (params) => {
    try {
      const response = await axios({
        method: "get",
        url: "userroles/ushers/list",
        params: params,
      });
      return response.data;
    } catch (err) {
      //return rejectWithValue(err.response.data);
    }
  }
);

export const CreateUsher = createAsyncThunk(
  "create/usher",
  async ({ body }, { dispatch }) => {
    const id = body.get("event_id");
    try {
      const response = await axios({
        method: "post",
        url: "userroles/ushers/create",

        data: body,
      });

      if (response.data.status == 0) {
        toast.success("Usher Created successfully");
        dispatch(getUshers({ id: id, pagenumber: 0 }));
      } else {
        toast.error(`${Object.values(response.data.record)}`);
      }
      return response.data;
    } catch (err) {
      //return rejectWithValue(err.response.data);
    }
  }
);
export const CreateOrganizationalUsher = createAsyncThunk(
  "create/usher",
  async ({ body }, { dispatch }) => {
    try {
      const response = await axios({
        method: "post",
        url: "userroles/ushers/create",
        data: body,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      if (response.data.status == 0) {
        toast.success("Usher Created successfully");
        dispatch(
          getAllUshers({
            pagenumber: 0,
          })
        );
      } else {
        toast.error(`${Object.values(response.data.record)}`);
      }
      return response.data;
    } catch (err) {
      //return rejectWithValue(err.response.data);
    }
  }
);
export const EditUsher = createAsyncThunk(
  "edit/usher",
  async ({ body, isAll = false, pageNumber }, { dispatch }) => {
    const id = body.get("event_id");

    try {
      const response = await axios({
        method: "post",
        url: "userroles/ushers/update",
        data: body,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      if (response.data.status == 0) {
        toast.success("Usher updated successfully !");
        if (isAll) {
          dispatch(getAllUshers({ id: id, pagenumber: pageNumber }));
        } else {
          dispatch(getUshers({ id: id, pagenumber: 0 }));
        }
      } else {
        toast.error(`${Object.values(response.data.record)}`);
      }
      return response.data;
    } catch (err) {
      //return rejectWithValue(err.response.data);
    }
  }
);
