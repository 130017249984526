import React from "react";
import "./AddModelPopup.css";
import Modal from "react-modal";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { useAppDispatch } from "redux/store";
import { useEffect, useState } from "react";
import { getTicketTypesConst } from "redux/TicketTypes/actions";
import { useSelector } from "react-redux";
import { object, string, number } from "yup";
import { IoMdClose } from "react-icons/io";
import PhoneInput from "react-phone-input-2";
import Select from "react-select";
import { AddNewAttende } from "redux/Attendee/actions";

const customStyles = {
  content: {
    width: "600px",
    maxWidth: "100%",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

function AddModelPopup({ AddPopup, SetAddPopup }) {
  const Dispatch = useAppDispatch();
  const [selectedTicket, setSelectedTicket] = useState(null);
  const { ticketTypesConst, loader } = useSelector(
    (state) => state.ticketTypes
  );
  const { managed_events } = useSelector((state) => state.user.user.user);

  useEffect(() => {
    Dispatch(getTicketTypesConst({ event_id: selectedTicket?.value }));
  }, [selectedTicket]);

  return (
    <Modal
      isOpen={AddPopup}
      onRequestClose={() => {
        SetAddPopup(false);
        setSelectedTicket(null);
      }}
      style={customStyles}
    >
      <div className="AddModelPopup">
        <button
          className="btn btn-light mb-4 rounded-circle"
          onClick={() => {
            SetAddPopup(false);
            setSelectedTicket(null);
          }}
        >
          <IoMdClose />
        </button>
        <Formik
          initialValues={{
            name: "",
            lname: "",
            mobileno: "",
            email: "",
            event_id: "",
            ticketid: "",
            tickets: [],
            quantity: 1,
            guests: [],
            payment_method: "online",
            questions: [],
            walletnumber: "",
          }}
          onSubmit={(values) => {
            const handleAnswers = values.questions.map((question) => ({
              questionid: question.id,
              answer:
                question.type == "checkbox"
                  ? question.options
                      .filter((el) => el.selected == 1)
                      .map((el) => el.id)
                  : question.defaultvalue,
            }));
            const body = { ...values };
            delete body.questions;

            Dispatch(
              AddNewAttende({
                body: {
                  ...body,
                  answers: handleAnswers,
                  tickets: [{ ticketid: values.ticketid, quantity: 1 }],
                },
                event_id: values.event_id,
              })
            );
          }}
          validationSchema={object({
            name: string().required("First name is a required field !"),
            lname: string().required("Last name is a required field !"),
            email: string().email().required(),
            quantity: number().min(1).required(),
          })}
        >
          {({ values, setFieldValue }) => (
            <Form className="AddPopupForm">
              <div className="Flex-Box">
                <div className="Input-Box">
                  <Field type="text" placeholder="First name" name="name" />
                  <ErrorMessage name="name" className="Error" component="div" />
                </div>
                <div className="Input-Box">
                  <Field type="text" placeholder="Last name" name="lname" />
                  <ErrorMessage
                    name="lname"
                    className="Error"
                    component="div"
                  />
                </div>
              </div>

              <div className="Input-Box">
                <PhoneInput
                  value={values.mobileno}
                  onChange={(phone) => setFieldValue("mobileno", phone)}
                  specialLabel=""
                  inputClass="PhoneInputFiels"
                />
              </div>
              <div className="Input-Box">
                <Field type="email" placeholder="Email" name="email" />
                <ErrorMessage name="email" className="Error" component="div" />
              </div>
              <div className="Input-Box">
                <Select
                  onChange={(values) => {
                    setSelectedTicket(values);
                    setFieldValue("event_id", values.value);
                  }}
                  name="event_id"
                  isMulti={false}
                  options={managed_events.map((Event) => ({
                    label: Event.name,
                    value: Event.id,
                  }))}
                  placeholder="Event name"
                />
              </div>
              <div className="Input-Box">
                <Select
                  isLoading={loader}
                  onChange={(values) => {
                    setFieldValue("tickets", values);
                    setFieldValue("ticketid", values.value);
                    setFieldValue("questions", values.questions);
                  }}
                  name="tickets"
                  isMulti={false}
                  options={ticketTypesConst
                    ?.filter((el) => el.price == 0)
                    ?.map((ticketType) => ({
                      label: ticketType.name,
                      value: ticketType.id,
                      questions: ticketType.questions,
                    }))}
                />
              </div>
              {/* <div className="Input-Box">
                <Field type="number" placeholder="Quantity" name="quantity" />
                <ErrorMessage
                  name="quantity"
                  className="Error"
                  component="div"
                />
              </div> */}
              <div className="QuestionRepeater">
                {values.questions.length > 0 &&
                  values.questions.map((el, index) => {
                    switch (el.type) {
                      case "text":
                        return (
                          <QuestionInputText
                            key={index}
                            el={el}
                            values={values}
                            setFieldValue={setFieldValue}
                            index={index}
                          />
                        );
                      case "radiobutton":
                        return (
                          <QuestionInputRadio
                            key={index}
                            el={el}
                            values={values}
                            setFieldValue={setFieldValue}
                            index={index}
                          />
                        );
                      case "checkbox":
                        return (
                          <QuestionInputCheckBox
                            key={index}
                            el={el}
                            values={values}
                            setFieldValue={setFieldValue}
                            index={index}
                          />
                        );
                      case "image":
                        return (
                          <QuestionInputImage
                            key={index}
                            el={el}
                            values={values}
                            setFieldValue={setFieldValue}
                            index={index}
                          />
                        );
                    }
                  })}
              </div>
              <button className="SubmitBtn" type="submit">
                Submit
              </button>
            </Form>
          )}
        </Formik>
      </div>
    </Modal>
  );
}
export default AddModelPopup;

// Questions Style input

const QuestionInputText = ({ el, values, setFieldValue, index }) => {
  return (
    <div className={`ShownQuestions TextInput`}>
      <h1>{el.name}</h1>
      <p>{el.shortdesc}</p>
      <input
        type="text"
        placeholder={"Answer"}
        value={el.defaultvalue}
        onChange={(e) => {
          let OldQu = [...values.questions];
          OldQu[index] = { ...OldQu[index], defaultvalue: e.target.value };
          setFieldValue("questions", OldQu);
        }}
      />
    </div>
  );
};

const QuestionInputRadio = ({ el, values, setFieldValue, index }) => {
  return (
    <div className={`ShownQuestions RadioInput`}>
      <h1>{el.name}</h1>
      <p>{el.shortdesc}</p>
      <div className="RadioSelectContainer">
        {el.options.map((Option, SelectIndex) => (
          <div className="RadioBox" key={SelectIndex}>
            <input
              id={Option.name}
              name={el.name}
              type="radio"
              checked={Option.selected == 1 ? true : false}
              onChange={() => {
                let OldQu = [...values.questions];
                let OldSelected = [...OldQu[index].options];
                OldSelected = OldSelected.map((Options) => ({
                  ...Options,
                  selected: 0,
                }));
                OldSelected[SelectIndex] = {
                  ...OldSelected[SelectIndex],
                  selected: 1,
                };
                OldQu[index] = {
                  ...OldQu[index],
                  options: OldSelected,
                  defaultvalue: OldSelected[SelectIndex].id,
                };
                setFieldValue("questions", OldQu);
              }}
            />
            <label htmlFor={Option.name}>{Option.name}</label>
          </div>
        ))}
      </div>
    </div>
  );
};

const QuestionInputCheckBox = ({ el, values, setFieldValue, index }) => {
  return (
    <div className={`ShownQuestions ChekBoxInput`}>
      <h1>{el.name}</h1>
      <p>{el.shortdesc}</p>
      <div className="RadioSelectContainer">
        {el.options.map((Option, SelectIndex) => (
          <div className="RadioBox" key={SelectIndex}>
            <input
              id={Option.name}
              name={el.name}
              type="checkbox"
              checked={Option.selected == 1 ? true : false}
              onChange={() => {
                let OldQu = [...values.questions];
                let OldSelected = [...OldQu[index].options];
                OldSelected[SelectIndex] = {
                  ...OldSelected[SelectIndex],
                  selected: OldSelected[SelectIndex].selected == 1 ? 0 : 1,
                };
                OldQu[index] = { ...OldQu[index], options: OldSelected };
                setFieldValue("questions", OldQu);
              }}
            />
            <label htmlFor={Option.name}>{Option.name}</label>
          </div>
        ))}
      </div>
    </div>
  );
};

const QuestionInputImage = ({ el, values, setFieldValue, index }) => {
  function imageToBase64(file) {
    return new Promise((resolve, reject) => {
      if (!file) {
        return;
      }
      const reader = new FileReader();
      reader.onload = () => {
        const base64String = reader.result;
        resolve(base64String);
      };
      reader.onerror = () => {
        reject(new Error("Error reading the file"));
      };
      reader.readAsDataURL(file); // Read the file as a data URL
    });
  }
  return (
    <div className={`ShownQuestions ImageInput`}>
      <h1>{el.name}</h1>
      <p>{el.shortdesc}</p>
      <input
        type="file"
        onChange={async (e) => {
          let OldQu = [...values.questions];
          OldQu[index] = {
            ...OldQu[index],
            defaultvalue: await imageToBase64(e.target.files[0]),
          };
          setFieldValue("questions", OldQu);
        }}
      />
    </div>
  );
};
